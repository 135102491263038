.chip {
  border: 1px solid var(--bs-primary);
  color: var(--bs-primary);
  border-radius: 50%;
  @include size(1.5em);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: all 0.2s;

  &.is--active,
  &:hover {
    background-color: var(--bs-primary);
    color: white;
  }
}
