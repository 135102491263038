body {
  @each $name, $color in $theme-colors {
    @include defineColorHSL(--bs-#{$name}, hue($color), saturation($color), lightness($color));
    --bs-#{$name}-rgb: #{red($color), green($color), blue($color)};
  }
  @each $name, $color in $colors {
    @include defineColorHSL(--bs-#{$name}, hue($color), saturation($color), lightness($color));
  }
  @each $name, $color in $grays {
    @include defineColorHSL(--bs-gray-#{$name}, hue($color), saturation($color), lightness($color));
  }

  // Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --#{$variable-prefix}font-sans-serif: #{inspect($font-family-sans-serif)};
  --#{$variable-prefix}font-monospace: #{inspect($font-family-monospace)};
  --#{$variable-prefix}gradient: #{$gradient};
  --#{$variable-prefix}gutter-x: #{$grid-gutter-width};
}
