.content-block-logos {
  &__gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    gap: 10px;

    img {
      max-width: 180px;
      max-height: 150px;
      padding: 10px;
      object-fit: contain;
      filter: grayscale(100%);
      transition: filter 0.2s ease-in-out;

      &:hover {
        filter: grayscale(0%);
      }
      @include media-breakpoint-down(lg) {
        max-width: 130px;
      }
      @include media-breakpoint-down(md) {
        max-width: 110px;
      }
    }
  }
}

.content-block-logos__header {
  max-width: 400px;
}
