.alert {
  border: 1px solid var(--bs-info);
  background: alpha(var(--bs-info), 0.2);
  color: var(--bs-info);
  border-radius: 7px;
  padding: 13px;
  font-weight: 600;
  font-size: 0.9rem;

  &--danger {
    border-color: var(--bs-danger);
    background: alpha(var(--bs-danger), 0.15);
    color: var(--bs-danger);
  }
}
