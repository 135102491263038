.button.button-outline {
  background-color: transparent;
  border: 1.5px solid theme-color(primary);
  color: theme-color(primary);

  .icon * {
    fill: theme-color(primary);
  }

  @media (hover: hover) {
    &:hover {
      background: rgba(theme-color(primary), 0.2);
      color: theme-color(primary);

      .icon * {
        fill: theme-color(primary);
      }
    }
  }
}
