.stepper {
  display: flex;
  gap: rem(10px);
  flex-wrap: wrap;
  justify-content: center;

  @include media-breakpoint-up(md) {
    font-size: rem(18px);
  }

  &__item {
    display: flex;
    align-items: center;
    gap: rem(10px);

    &:not(:last-child):after {
      content: '';
      height: 1px;
      background: var(--bs-primary);
      width: 40px;

      @include media-breakpoint-down(md) {
        width: 20px;
      }
    }
  }
}

.step {
  display: inline-flex;
  gap: 5px;
  font-weight: 600;
  align-items: center;

  &.is--active {
    color: var(--bs-primary);
  }
  &:hover {
    .chip {
      background-color: var(--bs-primary);
      color: white;
    }
  }

  @include media-breakpoint-down(md) {
    &__label {
      display: none;
    }
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;

    // .chip {
    //   color: rgb(79, 79, 79);
    //   border-color: rgb(79, 79, 79);
    // }
  }
}
