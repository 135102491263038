.theme-checker {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 99;
}

app-content-block-container {
  display: block;
  overflow: hidden;
}
.is--default {
  background-color: var(--bs-light);
}

.content-block-list__item {
  &.is--1 {
    .content-block-default__title {
      color: var(--bs-primary);
    }
  }
  &.is--2 {
    .button--primary {
      @extend .button-link;
    }
    .content-block-default__figure {
      order: 2;
    }
  }
  &.is--3 {
    @extend .skew-top-right;
    background-color: var(--bs-primary-100);
    .content-block-default__figure {
      order: 2;
    }
    .content-block-default__title {
      color: var(--bs-primary);
    }
  }
  &.has--text-only {
    &::before {
      border: none !important;
    }
  }

  &:not(.block--default) + .block--default {
    &::before {
      border: none !important;
    }
  }
}
