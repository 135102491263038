@each $name, $colors in $button-styles {
  $font-color: nth($colors, 1);
  $bg-color: nth($colors, 2);

  .button.button--#{$name} {
    background: $bg-color;
    color: $font-color;

    .icon * {
      fill: $font-color;
    }

    @media (hover: hover) {
      &:hover {
        background-color: darkenColor($bg-color, 0.2);
        color: $font-color;

        .icon * {
          fill: $font-color;
        }
      }
    }
  }

  .button-link.button--#{$name} {
    background: transparent;
    color: $bg-color;

    .icon * {
      fill: $bg-color;
    }

    @media (hover: hover) {
      &:hover {
        background: transparent;
        color: darkenColor($bg-color, 0.4);

        .icon * {
          fill: darkenColor($bg-color, 0.4);
        }
      }
    }
  }
}
