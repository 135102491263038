.footer {
  background: var(--bs-primary-100);
  text-align: center;
}

.footer__nav-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: gray-color(600);

  &__item {
    a {
      text-decoration: none;
    }
  }

  &__item:not(:last-child):after {
    content: '-';
    margin-inline: 0.3em;
    display: inline-block;
  }
}
