.content-block-steps__list {
  counter-reset: steps-counter;
  list-style: none;
}

.content-block-steps__list__item {
  --counter-size: 50px;
  --counter-bg-color: var(--bs-gray-200);
  --counter-color: var(--bs-gray-600);
  --counter-title-color: var(--bs-gray-500);

  padding-left: calc(var(--counter-size) * 1.5);
  padding-top: 7px;
  padding-bottom: map-get($spacers, 3);
  counter-increment: steps-counter;
  position: relative;
  color: var(--bs-primary);

  @include media-breakpoint-down(md) {
    --counter-size: 30px;
    padding-top: 1px;
  }

  &::before {
    @include size(var(--counter-size));
    position: absolute;
    left: 0;
    top: 0;
    content: counter(steps-counter);
    border-radius: 50%;
    color: var(--counter-color);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    background-color: var(--counter-bg-color);
    z-index: 2;
    font-size: 0.9em;

    @include media-breakpoint-up(md) {
      font-size: 1.2em;
    }
  }

  &::after {
    @include size(1px, 100%);
    @include abs(0, null, null, calc(var(--counter-size) / 2));
    content: '';
    background-color: var(--counter-bg-color);
    pointer-events: none;
    z-index: 1;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  &.set--current {
    --counter-bg-color: var(--bs-primary);
    --counter-color: #ffffff;
    --counter-title-color: var(--bs-primary);
  }
}

.steps-card {
  max-width: 700px;
}

.steps-card__text {
  flex: 1;
}

.steps-card__figure {
  width: 200px;
}

.steps-card__button {
  pointer-events: all;
  text-align: left;

  .set--current & {
    pointer-events: none;
  }
}

.steps-card__main {
  padding-right: 2ex;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s cubic-bezier(0, 1, 0, 1), opacity 0.3s ease-in-out;
  opacity: 0;

  .set--current & {
    max-height: 2000px;
    transition: max-height 0.6s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 1;
  }
}

.steps-card__title {
  color: var(--counter-title-color);
}
