// Bootstrap
$container-max-widths: (
  sm: 600px,
  md: 800px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

$container-padding-x: $grid-gutter-width / 2;

// Other
//? Put own extra variables here
$container-sizes: (
  sm: 800px,
  md: 1200px,
  xl: 1400px,
  xxl: 1600px,
);
