blockquote {
  color: var(--bs-primary);
  position: relative;
  padding-left: 40px;
  margin: 4ex 0 4ex 0;
  font-family: $font-family-serif;

  &::after,
  &::before {
    content: '“';
    font-size: 4em;
    font-family: arial;
    line-height: 0;
    line-height: 0;
    font-weight: bold;
    // opacity: 0.2;
    transform: translate(-50%, 0);

    @include media-breakpoint-down(md) {
      font-size: 4em;
    }
  }

  &::before {
    position: absolute;
    left: 0;
    top: 0.3em;
  }

  &::after {
    position: absolute;
    right: 0;
    bottom: -9px;
    content: '”';
    transform: translateX(50%);
    display: none;
  }

  * {
    font-size: map-get($font-sizes, 4) !important;
    font-weight: 100;
    line-height: 1.6;
    margin: 0 !important;
    text-transform: lowercase;

    @include media-breakpoint-down(md) {
      font-size: map-get($font-sizes, 5) !important;
    }
  }
}
