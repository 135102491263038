.content-block-tips__row__list {
  display: flex;
  flex-wrap: wrap;
  gap: map-get($spacers, 4);
  justify-content: space-between;
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-auto-flow: dense;
}

.content-block-tips__item {
  @include media-breakpoint-up(md) {
    width: 45%;

    &:nth-of-type(odd) {
      // transform: translateY(-40px);
    }

    &:nth-of-type(even) {
      // transform: translateY(40px);
      margin-top: 40px;
    }
  }
}

.content-block-tips__item__desc,
.content-block-tips__item__title {
  float: right;
  width: 80%;
  padding-left: map-get($spacers, 3);

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.content-block-tips__item__desc {
  @include media-breakpoint-up(md) {
    padding-right: map-get($spacers, 5);
  }
}

.content-block-tips__item__title {
  padding-top: map-get($spacers, 4);
  text-transform: uppercase;

  span {
    position: relative;
    display: inline-block;
    padding-bottom: 0.4ex;
    margin-bottom: 0.4ex;

    &::before {
      content: '';
      width: 30%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-bottom: 1px solid;
    }
  }
}

.content-block-tips__item__image {
  float: left;
  width: 20%;
}
