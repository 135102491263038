app-icon {
  &.icon-chevron-right .icon {
    padding: 3px;
  }
}
.content-block-default {
  position: relative;
  &__inner {
    max-width: 800px;
  }
  &__figure {
    text-align: center;
    @include media-breakpoint-down(sm) {
      order: -1;
    }
    img {
      object-fit: contain;
      max-height: 75vh;
      @include media-breakpoint-down(sm) {
        max-height: 50vh;
      }
    }
  }
  &.has--text-only {
    .content-block-default__main {
      width: 100%;
      text-align: center;
    }
    .content-block-default__title {
      color: var(--bs-primary);
    }
  }
}

%skew-top-before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
}

.skew-top {
  &-left {
    &::before {
      @extend %skew-top-before;
      border-top: 10vh solid var(--bs-primary-100);
      border-right: 100vw solid transparent;

      @include media-breakpoint-down(sm) {
        border-top: 5vh solid var(--bs-primary-100);
      }
    }
  }
  &-right {
    &::before {
      @extend %skew-top-before;
      border-top: 10vh solid var(--bs-light);
      border-left: 100vw solid transparent;

      @include media-breakpoint-down(sm) {
        border-top: 5vh solid var(--bs-light);
      }
    }
  }
}
