.content-block-fullwidth {
  background-color: var(--bs-primary);
  position: relative;
  overflow: hidden;
  // margin-top: -100px;

  &__inner {
    color: var(--bs-light);
    max-width: 800px;
    z-index: 1;
  }
  figure {
    position: absolute;
    width: 400px;
    right: 0;
    z-index: 0;
    @include media-breakpoint-down(xl) {
      opacity: 0.2;
    }
  }
}
