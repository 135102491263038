// Bootstrap
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);


$grid-columns: 12 ;
$grid-gutter-width: 1.5rem;
$grid-row-columns: 6;

$gutters: $spacers;

// Other
//? Put own extra variables here
