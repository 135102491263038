.mat-form-field {
  width: 100%;
}

.form.row {
  --bs-gutter-y: 20px;
}

.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
  color: var(--bs-primary);
}

// Prevents from text getting clipped
.mat-input-element,
.mat-form-field-label,
.mat-hint,
.mat-select-value {
  height: 1.27em;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.85em 0 0.85em;
}
