.grid-overlay {
  @include fxd(0);
  z-index: 9999;
  pointer-events: none; 

  .grid-showcase {
    // outline: 1px solid color(blue);

    &__inner {
      // outline: 1px solid color(pink);
      height: 100vh;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background: #ff000026;
      outline: 1px solid #ff00004a;
    }
  }
}

