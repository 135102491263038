.header {
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(241, 243, 247, 1) 100%);

  &__crop {
    img {
      object-fit: contain;
      max-height: 60vh;
      @include media-breakpoint-down(sm) {
        max-height: 50vh;
      }
    }
  }
  &.skew--bottom {
    &::after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      border-top: 10vh solid var(--bs-primary-100);
      border-right: 100vw solid transparent;

      @include media-breakpoint-down(sm) {
        border-top: 5vh solid var(--bs-primary-100);
      }
    }
  }
}
