.text-max-width {
  max-width: 720px;

  &-xs {
    max-width: 550px;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.text-decoration-none {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
