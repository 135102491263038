em,
i {
  font-style: italic;
}

strong {
  font-weight: 700;
}

@each $name, $size in $markdown-font-sizes {
  * + #{$name},
  * + .#{$name} {
    margin-top: map-get($spacers, 6);
  }

  #{$name},
  .#{$name} {
    font-size: rem($size);
    margin-bottom: 0.6em;
    font-weight: 500;
    color: var(--bs-primary);
  }
}

ul,
ol {
  list-style: outside;
  padding-inline-start: map-get($spacers, 4);
  display: inline-block;
  margin-bottom: rem(20px);

  li {
    display: list-item;
    list-style-type: initial;
  }
}

ol li {
  list-style-type: decimal;
}

hr {
  margin: 1.5em 0;
}

p {
  margin-bottom: 3ex;
}
