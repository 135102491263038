.app {
  position: relative;
}

.app__router {
  position: relative;
  min-height: calc(var(--vh, 1vh) * 100);
}

.app__router.ng-animating + app-footer {
  opacity: 0;
}
