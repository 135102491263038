@each $name, $color in $theme-colors {
  .bg-#{$name} {
    background-color: var(--bs-#{$name})!important;
  }
}

@each $name, $color in $colors {
  .bg-color-#{$name} {
    background-color: var(--bs-#{$name})!important;
  }
}

@each $name, $color in $grays {
  .bg-gray-#{$name} {
    background-color: var(--bs-gray-#{$name})!important;
  }
}