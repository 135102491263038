@include media-breakpoint-down(md) {
  .main-nav--active {
    position: absolute;
    overflow: hidden;
    max-height: 100vh;
    width: 100vw;
  }
}

.topbar-spacer {
  padding-top: 110px;

  @include media-breakpoint-down(md) {
    padding-top: 58px;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.topbar {
  @include abs(0, 0, auto, 0);
  padding-top: 5px;
  z-index: 999;

  &.set--fixed:not(.is--open) {
    position: fixed;
    background: white;
    box-shadow: 0px 6px 12px 0px #00000014;
    padding: 10px 0;
    animation: slideIn 0.2s forwards;

    .topbar__logo__big {
      display: block;
      max-width: none;
      max-height: 55px;
    }
    .topbar__logo__small {
      display: none;
    }
  }

  &.is--open {
    .topbar__inner {
      position: fixed;
      left: 0;
      right: 0;
    }
  }

  .topbar__logo__big {
    display: block;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  .topbar__logo__small {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    a {
      display: block;
    }
    img {
      max-width: 160px;

      @include media-breakpoint-down(md) {
        max-width: 130px;
        max-width: none;
        max-height: 45px;
      }
    }
  }

  &__nav {
    @include media-breakpoint-down(md) {
      @include fxd(0);
      background-color: var(--bs-primary);
      color: white;
      padding-top: 120px;
      opacity: 0;
      z-index: -1;
      pointer-events: none;
      transition: opacity 0.2s;

      &.is--active {
        opacity: 1;
        z-index: 98;
        pointer-events: auto;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__nav__list {
    display: flex;
    align-items: center;
    gap: 30px;

    @include media-breakpoint-down(md) {
      flex-flow: column;

      .button--generate {
        color: #fff;
        border-color: currentColor;
      }
    }

    &__item {
      font-weight: 600;

      .is--current {
        color: var(--bs-primary);
        font-weight: bold;

        @include media-breakpoint-down(md) {
          color: white;
          text-decoration: underline;
        }
      }
    }
  }

  &__hamburger {
    color: theme-color(primary);
    font-weight: 600;
    display: flex;
    gap: 10px;
    align-items: center;
    z-index: 99;
    position: relative;

    &.is--active {
      color: white;
    }
  }
}
