.embed-removed {
  background-color: var(--bs-primary);

  .fstr-cookie-btn.button {
    background: var(--bs-secondary);

    &:hover {
      background: darkenColor(var(--bs-secondary), 0.2);
    }
  }

  .embed-removed__text {
    font-weight: 600;
    color: white;
  }
}
