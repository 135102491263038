// Custom Theming for Angular Material
@use '@angular/material' as mat;

@include mat.core();

// Project specific settings
$mat-primary-map: makeColorPalette(theme-color(primary));
$mat-accent-map: makeColorPalette(theme-color(secondary));
$mat-warn-map: makeColorPalette(theme-color(danger));

$mat-typography: mat.define-typography-config(
  $font-family: $font-family-sans-serif,
);

/*
  Material implementation
*/ 

$geluksacademie-theme: mat.define-light-theme((
 color: (
   primary: mat.define-palette($mat-primary-map),
   accent: mat.define-palette($mat-accent-map),
   warn: mat.define-palette($mat-warn-map)
 ),
 typography: $mat-typography,
));

@include mat.core-theme($geluksacademie-theme);
@include mat.all-component-themes($geluksacademie-theme);
