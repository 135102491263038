.button-style {
  padding: 10px 10px 20px;
  min-height: 120px;

  .text-muted {
    font-size: 12px;
    margin-bottom: 6px;
    display: inline-block;
  }
}
