@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--bs-gray-300);
    border-radius: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--bs-primary);
  }
}
