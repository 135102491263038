.markdown-content {
  $markdown-font-sizes: (
    h1: 42px,
    h2: 38px,
    h3: 34px,
    h4: 20px,
    h5: 18px,
    h6: 18px,
  );

  $markdown-block-spacing-t: map-get($spacers, 4);
  $markdown-block-spacing-b: map-get($spacers, 6);

  iframe,
  object,
  embed {
    width: 100%;
    height: auto;
  }

  img {
    max-width: 100%;

    // @supports (width: fill-available) or (width: -webkit-fill-available) {
    //   @include media-breakpoint-up(lg) {
    //     --outset: -100px;
    //     max-width: max-content;
    //     margin-left: var(--outset);
    //     margin-right: var(--outset);
    //     width: -webkit-fill-available;
    //   }
    //   @include media-breakpoint-up(xxl) {
    //     --outset: -150px;
    //   }
    // }
  }

  @import './blockquote';
  @import './image';
  @import './table';
  @import './type';
  @import './video';
}

@import './simple-markdown';
@import './embed-removed';
