.button-link {
  background: transparent;
  color: var(--bs-dark);
  padding-left: 0;
  padding-right: 0;

  .icon * {
    fill: var(--bs-dark);
    transition: fill .2s;
  }

  @media (hover: hover) {
    &:hover {
      background: transparent;
      color: lightenColor(var(--bs-dark), 0.1);

      .icon * {
        fill: lightenColor(var(--bs-dark), 0.1);
      }
    }
  }
}