.lang-icon {
  @include size(var(--icon-size, 40px));
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.lang-switcher {
  --icon-size: 30px;
  --border-size: 5px;
  position: relative;

  @include media-breakpoint-down(md) {
    --icon-size: 25px;
  }

  &__toggle {
    border-radius: 50%;
    border: 5px solid #bdd3ff52;
  }

  &__dropdown {
    --icon-size: 23px;

    @include abs(calc(var(--icon-size) + var(--border-size) * 2), var(--border-size), auto, auto);
    background: #e7efff;
    border-radius: 10px;
    padding: 15px;
    margin-top: 10px;
    max-height: 205px;
    overflow: auto;
    animation: fadeIn 0.2s ease-in forwards;
    @include scrollbar();

    &::after {
      --size: 8px;
      content: '';
      @include abs(calc(var(--size) * -0.5), calc(var(--icon-size) / 2 - var(--border-size)), auto, auto);
      @include size(var(--size));
      background: #e7efff;
      transform: rotate(45deg);
    }
  }
}

.lang-list {
  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid #4687ff36;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }
}

.lang-button {
  display: flex;
  align-items: center;
  gap: 9px;
  font-weight: 600;

  &.is--active {
    color: var(--bs-primary);
    font-weight: 700;
  }

  span {
    white-space: nowrap;
  }
}
