.fr-video {
  // @include aspect-ratio;
  position: relative;
  display: block;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: 2ex;

  iframe {
    // @extend .aspect-ratio-element;
    @include abs(0, 0, 0, 0);
    @include size(100%);
  }
}
