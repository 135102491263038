.grid-showcase {
  outline: 1px solid var(--bs-blue);

  &__inner {
    outline: 1px solid var(--bs-pink);
    height: 75vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}

.headings {
  display: flex;

  & > * {
    width: 50%;
  }

  .jumbo-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.icon-list {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: calc(100% / 3);
    display: flex;
    align-items: center;
  }
}

.button-styles {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > li {
    width: calc((100% / 3) - 20px);
    margin-bottom: 50px;
    border-bottom: 1px solid var(--bs-gray-500);
    padding-bottom: 50px;
    margin-left: -10px;

    @include media-breakpoint-down(md) {
      width: calc((100% / 2) - 20px);
    }

    @include media-breakpoint-down(sm) {
      width: calc((100%) - 20px);
    }
  }

  // fix: topbar overlap met link componentguide
}
.page--style-guide {
  .topbar {
    position: relative;
  }
}

.country-flag {
  width: 100px;
}
